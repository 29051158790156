import { Pipe, PipeTransform } from '@angular/core';

import { currencies } from '../constants/currencies';

@Pipe({
  name: 'price',
  standalone: false,
})
export class PricePipe implements PipeTransform {
  transform(price: string, currencyCode?: string): string {
    if (!currencyCode) {
      return price;
    }

    const formattedPrice = Number(price.replace(',', '')).toLocaleString('en-US').replace(',', ' ');

    const currency = currencies.find((c) => c.code === currencyCode);
    if (!currency) {
      // eslint-disable-next-line no-irregular-whitespace
      return `${formattedPrice} ${currencyCode}`;
    }
    if (currency.position === 'before') {
      // eslint-disable-next-line no-irregular-whitespace
      return `${currency.symbol} ${formattedPrice}`;
    } else {
      // eslint-disable-next-line no-irregular-whitespace
      return `${formattedPrice} ${currency.symbol}`;
    }
  }
}
