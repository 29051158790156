import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Orderline } from 'src/app/models/orderline';

@Component({
  selector: 'app-cancel-orderline-dialog',
  templateUrl: './cancel-orderline-dialog.component.html',
  styleUrls: ['./cancel-orderline-dialog.component.scss'],
  standalone: false,
})
export class CancelOrderlineDialogComponent implements OnInit {
  orderline: Orderline;

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.orderline = this.config.data?.orderline;
  }

  cancelOrder(): void {
    this.ref.close('cancel');
  }

  close(): void {
    this.ref.close(null);
  }
}
