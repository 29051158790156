import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-promotion-dialog',
  templateUrl: './promotion-dialog.component.html',
  styleUrls: ['./promotion-dialog.component.scss'],
  standalone: false,
})
export class PromotionDialogComponent implements OnInit {
  promotions: any = [];

  constructor(
    public ref: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.promotions = this.config.data?.promotions;
  }

  discard(): void {
    this.ref.close('');
  }

  close(): void {
    this.ref.close(null);
  }
}
