import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthenticationService } from './authentication.service';

const whiteListedURLs = ['/api/token/refresh', '/api/login'];

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const whiteListed = whiteListedURLs.find((wlu) => {
      return req.url.indexOf(wlu) !== -1;
    });
    if (whiteListed) {
      return next.handle(req);
    }
    if (req.headers.has('x-api-key')) {
      // direct auth
      return next.handle(req);
    }
    if (new RegExp(environment.apiURL).test(req.url) && !this.authenticationService.token) {
      this.authenticationService.logout();
      return EMPTY;
    }
    if (this.authenticationService.token) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authenticationService.token}`),
      });

      return next.handle(authReq).pipe(
        catchError((error: HttpErrorResponse) => {
          // 401 Unauthorized
          if (error.status === 401) {
            if (!this.authenticationService.token) {
              // logout & redirect to login page
              this.authenticationService.logout();
            } else {
              // refresh token and try again
              return this.authenticationService
                .refresh()
                .pipe(tap({ error: () => this.authenticationService.logout() })) // catchError ?
                .pipe(tap({ error: (err) => console.error(err) }))
                .pipe(
                  switchMap((newToken) => {
                    const newAuthReq = authReq.clone({
                      setHeaders: {
                        Authorization: `Bearer ${newToken.token}`,
                      },
                    });
                    return next.handle(newAuthReq.clone());
                  }),
                );
            }
          }
          throw error;
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
