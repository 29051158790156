import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: false,
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length: number, suffix = '…'): string {
    if (!value || value.length <= length) {
      return value ?? '';
    }
    const violentlyTruncated = value.slice(0, length + 1);
    const softlyTruncated = violentlyTruncated.replace(/ +[^ ]*$/, suffix);
    return softlyTruncated;
  }
}
