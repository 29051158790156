import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  filter,
  fromEvent,
  map,
  Observable,
  Subscription,
  switchMap,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';

const pullSize = 100;

const getCoords = function (
  event: TouchEvent,
  eventName: keyof TouchEvent,
): { x: number; y: number } {
  if (typeof event[eventName] !== 'undefined') {
    const touch = event.targetTouches[0];
    return {
      x: touch.screenX,
      y: touch.screenY,
    };
  }
  return {
    x: (event as any).screenX,
    y: (event as any).screenY,
  };
};

// source code: https://codepen.io/Vijit_Ail/pen/pmbypw
@Injectable({
  providedIn: 'root',
})
// singleton: should be provided directly in the component
export class PullToRefreshService {
  pStart = { x: 0, y: 0 };
  pCurrent = { x: 0, y: 0 };
  main: HTMLElement;
  body: HTMLElement;
  isLoading = false;
  subscription: Subscription;
  endSubscription: Subscription;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  onPullToRefresh(container: HTMLElement, getObs: () => Observable<any>): void {
    this.main = container;
    const wrapper = container.querySelector('.p-datatable-wrapper') as HTMLElement;
    this.body = container.querySelector('.p-datatable-tbody') as HTMLElement;

    this.subscription = fromEvent<TouchEvent>(this.main, 'touchstart')
      .pipe(
        filter(
          () =>
            // stop if scroll doesn't start from the top
            this.document.documentElement.scrollTop < 10 &&
            this.document.body.scrollTop < 10 &&
            wrapper.scrollTop <= 30,
        ),
        switchMap((startEvent: TouchEvent) => {
          this.pStart = getCoords(startEvent, 'targetTouches');
          // if touchstart ok, then listen to touchmove
          return fromEvent<TouchEvent>(this.main, 'touchmove').pipe(
            takeUntil(fromEvent<TouchEvent>(this.main, 'touchend')),
            map((e: TouchEvent) => {
              this.pCurrent = getCoords(e, 'changedTouches');
              return this.pCurrent.y - this.pStart.y;
            }),
            // stop if user scrolls up
            takeWhile((pullY: number) => pullY > -5),
          );
        }),
        filter((pullY: number) => {
          if (!this.isLoading) {
            // display a basic pull effect
            this.body.style.transform = `translateY(${pullY}px)`;
          }
          // continue if pull is big enough
          return pullY > pullSize && !this.isLoading;
        }),
        tap(() => {
          this.isLoading = true;
          // reset style
          this.body.style.transform = `translateY(0px)`;
        }),
        switchMap(getObs),
      )
      .subscribe({
        next: () => (this.isLoading = false),
        error: () => (this.isLoading = false),
      });

    this.endSubscription = fromEvent<TouchEvent>(this.main, 'touchend').subscribe(() => {
      // reset style
      this.body.style.transform = `translateY(0px)`;
    });
  }

  destroy(): void {
    this.subscription?.unsubscribe();
    this.endSubscription?.unsubscribe();
  }
}
