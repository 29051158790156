import { Injectable } from '@angular/core';
import { CanActivateChild, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class HasJwtGuard implements CanActivateChild {
  constructor(
    private storageService: StorageService,
    private router: Router,
  ) {}

  canActivateChild():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    // Redirect to the home page if not admin
    // const token = ;
    return this.storageService.get('token') !== null || this.router.parseUrl('/login');
  }
}
