import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounce as _debounce } from 'lodash';
import { MessageService, ToastMessageOptions } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService {
  constructor(private messageService: MessageService) {
    this.httpError = _debounce(this.httpError, 500);
  }

  httpError(error: HttpErrorResponse): void {
    const message: ToastMessageOptions = {
      severity: 'error',
      life: 10000,
      summary: error.error?.title || error.error?.code || error.statusText,
      detail: error.error?.detail || error.error?.message || error.message,
    };
    this.messageService.add(message);
  }

  warn(message: string): void {
    this.messageService.add({
      severity: 'warn',
      life: 10000,
      summary: message,
    });
  }

  error(message: string): void {
    this.messageService.add({
      severity: 'error',
      life: 10000,
      summary: message,
    });
  }

  info(message: string): void {
    this.messageService.add({
      severity: 'info',
      life: 10000,
      summary: message,
    });
  }

  success(message: string): void {
    this.messageService.add({
      severity: 'success',
      life: 10000,
      summary: message,
    });
  }
}
