import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  standalone: false,
})
export class DialogComponent {
  title: string;
  content: string;
  labelCancelButton: string;
  labelOkButton: string;
  onConfirm = () => {};

  constructor(
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {
    this.title = this.config.data.title;
    this.content = this.config.data.content;
    this.labelCancelButton = this.config.data.labelCancelButton;
    this.labelOkButton = this.config.data.labelOkButton;
    this.onConfirm = this.config.data.onConfirm || this.onConfirm;
  }

  close(): void {
    this.ref.close();
  }

  confirm(): void {
    this.onConfirm();
    this.close();
  }
}
