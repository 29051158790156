import { Component, Input } from '@angular/core';
import { FlightInfo } from 'src/app/models/flight-info';
import { gateHasChanged, gateKo, gateNotAvailable } from 'src/app/utils/boarding-pass';

@Component({
  selector: 'app-flight-number-and-gate',
  templateUrl: './flight-number-and-gate.component.html',
  styleUrls: ['./flight-number-and-gate.component.scss'],
  standalone: false,
})
export class FlightNumberAndGateComponent {
  @Input() flightInfo: FlightInfo;

  gateHasChanged = gateHasChanged;
  gateNotAvailable = gateNotAvailable;
  gateKo = gateKo;
}
