import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirm-force-collected-dialog',
  templateUrl: './confirm-force-collected-dialog.component.html',
  styleUrls: ['./confirm-force-collected-dialog.component.scss'],
  standalone: false,
})
export class ConfirmForceCollectedDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  confirm(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }
}
