import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import SignaturePad from 'signature_pad';
import { Target } from 'src/app/constants/target';
import { BoardingPassPayload } from 'src/app/models/boarding-pass';
import { ClientInfo } from 'src/app/models/client-info';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrderService } from 'src/app/services/order.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';

@Component({
  selector: 'app-signature-component',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SignatureComponent implements OnInit, OnDestroy {
  @ViewChild('signaturePad', { static: true }) canvas: ElementRef;
  @Input() orderId: string;
  @Input() orderIsPaid: boolean | null;
  @Input() target: string;
  @Input() passenger: string;
  @Input() boardingPass: Partial<ClientInfo>;
  @Input() bypassBoardingPass: boolean;
  @Output() onDisableDeliveryBtn = new EventEmitter<boolean>(true);
  signaturePad: SignaturePad;

  constructor(
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    private orderService: OrderService,
    private translateService: TranslateService,
    private router: Router,
    private toastMessageService: ToastMessagesService,
  ) {}

  ngOnDestroy(): void {
    // Unbinds all event handlers
    this.signaturePad.off();
  }

  ngOnInit() {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      backgroundColor: '#f5f7fb',
      penColor: 'black',
      minWidth: 1,
      maxWidth: 1,
    });
    this.signaturePad.addEventListener('endStroke', () => {
      this.onDisableDeliveryBtn.next(false);
    });
    this.setDefaultCanvas();
  }

  setDefaultCanvas(): void {
    this.onDisableDeliveryBtn.next(true);
    this.signaturePad.clear();
    const ctx = (this.canvas.nativeElement as HTMLCanvasElement).getContext('2d');
    const username = this.authenticationService.decodedToken.username;

    if (ctx) {
      ctx.textAlign = 'end';
      ctx.font = '100 12px Poppins';

      const text = this.translateService.instant('dialog.general-delivery.signature.delivered-by');

      ctx.fillText(`${text} ${username}`, this.canvas.nativeElement.offsetWidth - 20, 20);
      ctx.textAlign = 'center';
      ctx.fillText(
        `${this.passenger}, ${this.datePipe.transform(new Date())}`,
        this.canvas.nativeElement.offsetWidth / 2,
        this.canvas.nativeElement.offsetHeight - 10,
      );
    }
  }

  resetSignature() {
    this.setDefaultCanvas();
  }

  getBoardingPassToPayload(): Partial<BoardingPassPayload> | null {
    return this.boardingPass
      ? {
          passenger: this.boardingPass.passenger,
          departureDateTime: this.boardingPass.flightDate?.toISOString(),
          departureAirportIataCode: this.boardingPass.originAirport,
          arrivalAirportIataCode: this.boardingPass.destinationAirport,
          flightNumber: this.boardingPass.flightOperator! + this.boardingPass.flightNumber!,
          isTransit: !!this.boardingPass.transit,
          taxRegime: this.boardingPass.taxRegime,
        }
      : null;
  }

  delivery() {
    const payload = this.getBoardingPassToPayload();
    const boardingPass = this.target === Target.PAX && payload ? payload : undefined;
    // https://lagardere-travel-retail.atlassian.net/browse/ER-1163
    // when bypassBoardingPass === true, the boarding pass info will not be displayed
    // so we force bypass=false if can we have a boarding pass
    const bypassBoardingPass = boardingPass ? false : this.bypassBoardingPass;

    if (this.canDeliver() === false) {
      this.toastMessageService.warn(
        this.translateService.instant(`dialog.general-delivery.order-is-unpaid`),
      );
      return;
    }

    this.orderService
      .updateStatus(this.orderId, 'collect', {
        signature: this.signaturePad.toDataURL(),
        bypassBoardingPass,
        boardingPass,
      })
      .subscribe(() => {
        this.router.navigate(['orders', 'all']);
      });
  }

  canDeliver() {
    return this.orderIsPaid === true || this.orderIsPaid === null;
  }
}
