import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeFormat, Result } from '@zxing/library';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';
import { DATE_TIME_FORMATS } from 'src/app/constants/datetime-formats';
import { ClientInfo, TableValue } from 'src/app/models/client-info';
import { DateFormatValidator } from 'src/app/shared/validators/date-format.validator';
import { decode, slugify } from 'src/app/utils/boarding-pass';

import { Target } from '../../../../../../constants/target';

@Component({
  selector: 'app-boarding-pass-component',
  templateUrl: './boarding-pass.component.html',
  styleUrls: ['./boarding-pass.component.scss'],
  standalone: false,
})
export class BoardingPassComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() scannerResultEvent = new EventEmitter<Partial<ClientInfo>>(true);
  @Output() scannerBypassEvent = new EventEmitter<Partial<ClientInfo>>(true);
  @Output() redoScanEvent = new EventEmitter(true);
  @Input() bypassScan: boolean;
  @Input() flightInfo: TableValue<ClientInfo>[];
  @Input() orderExternalIdentifier: string;
  @ViewChild('scanner', { static: false })
  scanner!: ZXingScannerComponent;
  hasDevices!: boolean | undefined;
  infoEquality!: boolean;
  hasPermission!: boolean;
  qrResultString!: string;
  qrResult!: Result;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo | undefined;
  scanDisplay = false;
  scanDone = false;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.AZTEC,
    BarcodeFormat.PDF_417,
  ];
  qrInfo!: Partial<ClientInfo>;
  boardingPassQrInfo: TableValue<ClientInfo>[] = [
    { label: 'passenger', key: 'passenger' },
    { label: 'flight-date', key: 'flightDate' },
    { label: 'destination-airport', key: 'destinationAirport' },
    { label: 'origin-airport', key: 'originAirport' },
    { label: 'vol', key: 'vol' },
    { label: 'nationality', key: 'nationality' },
    { label: 'transit', key: 'transit' },
    { label: 'tax', key: 'tax' },
    { label: 'taxRegime', key: 'taxRegime' },
  ];

  comparableData: Array<string> = [
    'passenger',
    'flightDate',
    'destinationAirport',
    'originAirport',
    'vol',
  ];

  dateInputFormat =
    DATE_TIME_FORMATS.dateInput[(this.translateService.currentLang as 'en' | 'fr') ?? 'en'];
  passForm: FormGroup = this.fb.group(
    {
      passenger: ['', Validators.required],
      flightDate: new FormControl(null, {
        validators: [Validators.required, DateFormatValidator(this.dateInputFormat)],
        updateOn: 'blur', // prevent early error
      }),
      destinationAirport: ['', [Validators.required, Validators.minLength(3)]],
      originAirport: ['', [Validators.required, Validators.minLength(3)]],
      vol: ['', [Validators.required, Validators.minLength(4)]],
    },
    { updateOn: 'change' },
  );
  private destroySubject = new Subject();

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.passForm.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value) => {
      let flightDate: Date | undefined;
      if (value.flightDate) {
        const flightDateLuxon = DateTime.fromFormat(value.flightDate, this.dateInputFormat);
        flightDate = flightDateLuxon.toJSDate();
      }
      if (this.passForm.valid) {
        this.scannerResultEvent.emit({
          ...value,
          flightDate,
          flightNumber: value.vol,
          flightOperator: '',
        });
      } else {
        this.redoScanEvent.emit(true);
      }
    });
  }

  isDate(arg: any) {
    return arg instanceof Date;
  }

  ngAfterViewInit(): void {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.availableDevices = devices;
      this.hasDevices = !!devices?.length;
    });

    this.scanner.camerasNotFound.subscribe(() => {
      this.hasDevices = false;
    });

    this.scanner.scanComplete.subscribe((result: Result) => {
      this.qrResult = result;
    });

    this.scanner.permissionResponse.subscribe((perm: boolean) => {
      this.hasPermission = perm;
    });
  }

  toggleScan(): void {
    this.scanDisplay = !this.scanDisplay;
  }

  onBypassScanAction(): void {
    this.scanDone = true;
    this.scanDisplay = false;
    this.boardingPassQrInfo.forEach((qrRow) => {
      return (qrRow.value = 'err');
    });
    this.bypassScan = true;

    this.scannerBypassEvent.emit(this.qrInfo);
  }

  onDeviceSelectChange(selected: any) {
    this.currentDevice = this.availableDevices.find((x) => x.deviceId === selected.target.value);
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = !!devices?.length;
  }

  deleteInfo(): void {
    this.boardingPassQrInfo = [
      { label: 'passenger', key: 'passenger' },
      { label: 'flight-date', key: 'flightDate' },
      { label: 'destination-airport', key: 'destinationAirport' },
      { label: 'origin-airport', key: 'originAirport' },
      { label: 'vol', key: 'vol' },
      { label: 'nationality', key: 'nationality' },
      { label: 'transit', key: 'transit' },
      { label: 'tax', key: 'tax' },
      { label: 'taxRegime', key: 'taxRegime' },
    ];

    this.scanDone = false;
    this.bypassScan = false;
    this.redoScanEvent.emit(true);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  compare(newInfo: any, info: any, key: string) {
    let comparison = '';

    if (this.bypassScan) {
      return this.isComparableData(key) ? 'error' : '';
    }

    if (newInfo !== undefined) {
      if (typeof newInfo === 'string') {
        comparison = slugify(newInfo) === slugify(info) ? 'valid' : 'error';
      }

      if (this.isDate(newInfo)) {
        const diffTime = info.getTime() - newInfo.getTime();
        const diffDays = diffTime / (1000 * 3600 * 24);

        comparison = diffDays >= -1 && diffDays <= 1 ? 'valid' : 'error';
      }
    }

    return comparison;
  }

  isComparableData(key: string): boolean {
    return this.comparableData.includes(key);
  }

  handleQrCodeResult(resultString: string) {
    this.qrResultString = resultString;
    this.qrInfo = decode(resultString);
    this.scanDone = true;
    this.scanDisplay = false;

    this.boardingPassQrInfo.forEach((qrRow) => {
      return qrRow.key === 'vol'
        ? (qrRow.value = this.qrInfo.flightOperator! + this.qrInfo.flightNumber!)
        : (qrRow.value = this.qrInfo[qrRow.key]);
    });

    this.scannerResultEvent.emit(this.qrInfo);
  }

  protected readonly Target = Target;

  ngOnDestroy(): void {
    this.destroySubject.next(true);
  }
}
