import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-reset-tcpos-dialog',
  templateUrl: './reset-tcpos-dialog.component.html',
  styleUrls: ['./reset-tcpos-dialog.component.scss'],
  standalone: false,
})
export class ResetTcposDialogComponent {
  constructor(public ref: DynamicDialogRef) {}

  confirm(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }
}
