import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  bufferCount,
  map,
  Observable,
  repeatWhen,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { REMOTE_DISPLAY_REFRESH_TIMER } from 'src/app/constants/constants';
import { OrderStatus, statusIncomingCollect, statusToCancel } from 'src/app/constants/order-status';
import { Target } from 'src/app/constants/target';
import { Order } from 'src/app/models/order';
import { OrdersData } from 'src/app/models/orders-data';
import { RosData } from 'src/app/models/ros-data';
import { OrdersService } from 'src/app/services/orders.service';
import { RosService } from 'src/app/services/ros.service';
import { ToastMessagesService } from 'src/app/services/toast-messages.service';
import { DateTimeUtils } from 'src/app/utils/datetime.utils';

import { CarouselConfig } from './carousel-config';

@Component({
  selector: 'app-remote-display',
  templateUrl: './remote-display.component.html',
  styleUrls: ['./remote-display.component.scss'],
  standalone: false,
})
export class RemoteDisplayComponent implements OnInit, OnDestroy {
  ordersDataNew: OrdersData;
  ordersDataReadyForCollect: OrdersData;
  ordersDataIncomingRO: OrdersData;
  chunkedArrayNew: Order[][];
  chunkedArrayReadyForCollect: Order[][];
  chunkedArrayIncomingRO: Order[][];
  Target = Target;
  now: Date;
  carouselConfigMain: CarouselConfig = {
    groupItems: 4,
    numVisible: 2,
    numScroll: 1,
    verticalViewPortHeight: `${window.innerHeight - 120}px`, // eslint-disable-line
    autoplayInterval: 5000, // ms
  };
  carouselConfigSide: CarouselConfig = {
    groupItems: window.innerHeight > 800 ? 6 : 4, // eslint-disable-line
    numVisible: 1,
    numScroll: 1,
    verticalViewPortHeight: `${window.innerHeight / 2 - 120}px`, // eslint-disable-line
    autoplayInterval: 5000, // ms
  };
  restartPaging = new Subject<number>();
  refreshObs = timer(0, REMOTE_DISPLAY_REFRESH_TIMER * 1000)
    .pipe(
      tap((page) => {
        this.restartPaging.next(page);
      }),
    )
    .pipe(shareReplay());
  requestParamsNew = {
    orderStatus: [OrderStatus.NEW],
    'sort[orderDeadlinePreparation]': 'asc',
  };
  requestParamsIncomingCollect = {
    orderStatus: statusIncomingCollect,
    'sort[orderDeadlinePreparation]': 'asc',
  };
  requestParamsToCancel = {
    orderStatus: statusToCancel,
    'sort[orderCreatedOn]': 'asc',
  };
  requestParamsIncomingRO = {
    // 'sort[orderCreatedOn]': 'desc',
  };
  dataReceived = new Subject<any>();
  displaySync = this.dataReceived.pipe(bufferCount(3));
  page = 0;
  evenPage = 0;
  pageSync = timer(0, this.carouselConfigMain.autoplayInterval).pipe(
    takeUntil(this.restartPaging),
    repeatWhen(() => this.displaySync),
  );
  destroySubject = new Subject();
  DateTimeUtils = DateTimeUtils;
  showIncomingROBlock = true;
  nbOrdersToCancel$: Observable<number>;
  incomingRo$: Observable<RosData>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastMessagesService: ToastMessagesService,
    private rosService: RosService,
    private ordersService: OrdersService,
  ) {}

  ngOnInit(): void {
    if (!this.activatedRoute.snapshot.queryParams.shop) {
      this.toastMessagesService.error('Missing URL parameter: shop');
      return;
    }
    if (this.activatedRoute.snapshot.queryParams.demo) {
      this.toastMessagesService.warn('Demo mode');
    }
    this.refreshObs.pipe(takeUntil(this.destroySubject)).subscribe(() => (this.now = new Date()));
    this.displaySync.pipe(takeUntil(this.destroySubject)).subscribe(); // otherwise `delayWhen` subscribes too late
    this.pageSync.pipe(takeUntil(this.destroySubject)).subscribe((p) => {
      this.page = p;
      this.evenPage = Math.floor(p / 2);
    });
    const shop = this.activatedRoute.snapshot.queryParams.shop;
    this.nbOrdersToCancel$ = this.refreshObs.pipe(
      switchMap(() => {
        return this.ordersService.getAll({ ...this.requestParamsToCancel, shop }, shop);
      }),
      map((ordersData) => ordersData.meta.totalItems ?? 0),
    );
    this.incomingRo$ = this.refreshObs.pipe(
      switchMap(() =>
        this.rosService.getAllRemote({ ...this.requestParamsIncomingRO, shop }, shop),
      ),
      map((data: RosData) => ({
        ...data,
        items: data.items.slice(0, 6),
      })),
    );
  }

  onDataReceived() {
    this.dataReceived.next(null);
  }

  ngOnDestroy(): void {
    this.destroySubject.next(true);
  }
}
