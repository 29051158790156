import { Component } from '@angular/core';

import { AuthenticationService } from './services/authentication.service';
import { HistoryService } from './services/history.service';
import { I18nService } from './services/i18n.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor(
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private historyService: HistoryService,
  ) {
    this.i18nService.init();
    this.authenticationService.init().subscribe();
    this.historyService.init();
  }
}
