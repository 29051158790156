import { Component, Input } from '@angular/core';
import { Target } from 'src/app/constants/target';
import { Order } from 'src/app/models';

@Component({
  selector: 'app-order-pickup-point',
  templateUrl: './order-pickup-point.component.html',
  styleUrls: ['./order-pickup-point.component.scss'],
  standalone: false,
})
export class OrderPickupPointComponent {
  @Input() order: Order;
  data: Array<string> = [];

  ngOnInit() {
    this.data = ['shipping-point-label', 'collection-type', 'flight-number', 'flight-date'];
    if (this.order?.orderType === Target.VAP) {
      this.data = ['shipping-point-label'];
      if (this.order?.orderVapInfos?.withdrawalEndDate) {
        this.data.push('collection-period');
      } else {
        this.data.push('collection-date');
      }
    }
    if (this.order?.orderType === Target.FERRY) {
      this.data = ['ferry-number', 'ferry-date'];
    }
  }
}
