import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-order-unpaid-dialog',
  templateUrl: './order-unpaid-dialog.html',
  styleUrls: ['./order-unpaid-dialog.scss'],
  standalone: false,
})
export class OrderUnpaidDialogComponent {
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {}

  back() {
    this.ref.close('back');
  }

  openCancelValidation(): void {
    this.ref.close('openCancelValidationDialog');
  }
}
