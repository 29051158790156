import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderSignature } from 'src/app/models/order';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-signature-order-dialog',
  templateUrl: './signature-order-dialog.component.html',
  styleUrls: ['./signature-order-dialog.component.scss'],
  standalone: false,
})
export class SignatureOrderDialogComponent implements OnInit {
  orderSignature: OrderSignature;

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private orderService: OrderService,
  ) {}

  ngOnInit(): void {
    this.orderService.getSignatureById(this.config.data.orderId).subscribe((res) => {
      this.orderSignature = res;
    });
  }

  close(): void {
    this.ref.close(null);
  }
}
