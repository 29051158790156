import { Injectable } from '@angular/core';
import { filter, fromEvent, Observable, Subject, switchMap, take, takeUntil, timer } from 'rxjs';

export interface OnInfiniteScrollOptions {
  tableContainer: HTMLElement;
}

const scrollAnticipation = 160; // px

@Injectable({
  providedIn: 'root',
})
// singleton: should be provided directly in the component
export class InfiniteScrollService {
  private destroySubject = new Subject();

  constructor() {}

  onInfiniteScroll(options: OnInfiniteScrollOptions): Observable<any> {
    // wait for template
    return timer(100).pipe(
      switchMap(() => {
        const container = options.tableContainer;
        const wrapper = container.querySelector('.p-datatable-table-container') as HTMLElement;
        const table = container.querySelector('.p-datatable-table-container table') as HTMLElement;
        const maxScroll = -wrapper?.offsetHeight + table?.offsetHeight;
        return fromEvent(wrapper, 'scroll').pipe(
          takeUntil(this.destroySubject),
          filter(() => {
            return wrapper.scrollTop > maxScroll - scrollAnticipation;
          }),
          take(1),
        );
      }),
    );
  }

  destroy(): void {
    this.destroySubject.next(true);
  }
}
