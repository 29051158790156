<div class="p-dialog-header">
  <span class="p-dialog-title">{{ 'dialog.sku.title' | translate }}</span>
  <div class="p-dialog-header-icons">
    <button
      (click)="close()"
      type="button"
      class="p-dialog-header-icon p-dialog-header-maximize p-link"
    >
      <span class="p-dialog-header-close-icon pi pi-times"></span>
    </button>
  </div>
</div>
<div class="p-dialog-content">
  <p-progressSpinner *ngIf="!product?.productJsonDataM2"></p-progressSpinner>
  <img
    *ngIf="product?.productJsonDataM2 && imageUrl"
    width="180"
    height="180"
    [src]="imageUrl"
    alt=""
  />
  <dl class="sku-description" *ngIf="product?.productJsonDataM2">
    <dt>{{ 'dialog.sku.sku' | translate }}</dt>
    <dd>{{ product.productSku }}</dd>
    <dt>{{ 'dialog.sku.brands' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.brands?.labels | i18nLabel }}</dd>
    <dt>{{ 'dialog.sku.web-designation' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.name?.labels | i18nLabel }}</dd>
    <dt>{{ 'dialog.sku.sap-designation' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.erp_name }}</dd>
    <dt>{{ 'dialog.sku.capacity' | translate }}</dt>
    <dd>
      {{ product.productJsonDataM2.capacity }}
      {{ product.productJsonDataM2.capacity_unit }}
    </dd>
    <dt>{{ 'dialog.sku.sub-brand' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.franchise }}</dd>
    <dt>{{ 'dialog.sku.ean' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.ean }}</dd>
    <dt>{{ 'dialog.sku.ean-secondary' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.ean_secondary }}</dd>
    <dt>{{ 'dialog.sku.category' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.group?.labels | i18nLabel }}</dd>
    <dt>{{ 'dialog.sku.concentration' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.concentration?.labels | i18nLabel }}</dd>
    <dt>{{ 'dialog.sku.hue' | translate }}</dt>
    <dd>{{ product.productJsonDataM2.tints?.labels | i18nLabel }}</dd>
    <dt>{{ 'dialog.sku.weight' | translate }}</dt>
    <dd>
      {{ product.productJsonDataM2.net_weight }}
      {{ product.productJsonDataM2.net_weight_unit }}
    </dd>
    <dt>{{ 'dialog.sku.stock' | translate }}</dt>
    <dd class="dialog-sku-stock">
      <span *ngFor="let stock of orderline.stocks"
        >{{ stock.organizationCode }}: {{ stock.stock }}</span
      >
    </dd>
  </dl>
</div>
