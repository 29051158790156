import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Orderline } from 'src/app/models/orderline';
import { Product } from 'src/app/models/product';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-sku-dialog',
  templateUrl: './sku-dialog.component.html',
  styleUrls: ['./sku-dialog.component.scss'],
  standalone: false,
})
export class SkuDialogComponent implements OnInit {
  orderline: Orderline;
  product: Product;

  get imageUrl(): string {
    return this.orderline.productInfo?.images[0];
  }

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private productService: ProductService,
  ) {}

  ngOnInit(): void {
    this.orderline = this.config.data?.orderline;
    this.productService
      .getBySku(this.orderline.productSku)
      .subscribe((product) => (this.product = product));
  }

  close(): void {
    this.ref.close(null);
  }
}
