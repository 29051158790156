import { DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import Materiel from '@primeng/themes/material';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MessageService } from 'primeng/api';
import { providePrimeNG } from 'primeng/config';
import { DialogService } from 'primeng/dynamicdialog';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FullPageSpinnerComponent } from './components/full-page-spinner/full-page-spinner.component';
import { OlDetailsComponent } from './components/ol/ol-details/ol-details.component';
import { CancelOrderDialogComponent } from './components/order/order-details/cancel-order-dialog/cancel-order-dialog.component';
import { CancelOrderlineDialogComponent } from './components/order/order-details/cancel-orderline-dialog/cancel-orderline-dialog.component';
import { CancelValidationOrderDialogComponent } from './components/order/order-details/cancel-validation-order-dialog/cancel-validation-order-dialog.component';
import { ConfirmDeliveryDialogComponent } from './components/order/order-details/confirm-delivery-dialog/confirm-delivery-dialog.component';
import { ConfirmForceCollectedDialogComponent } from './components/order/order-details/confirm-force-collected-dialog/confirm-force-collected-dialog.component';
import { ConfirmForceToCollectDialogComponent } from './components/order/order-details/confirm-force-to-collect-dialog/confirm-force-to-collect-dialog.component';
import { BoardingPassComponent } from './components/order/order-details/general-delivery-dialog/components/boarding-pass/boarding-pass.component';
import { SignatureComponent } from './components/order/order-details/general-delivery-dialog/components/signature/signature.component';
import { GeneralDeliveryDialogComponent } from './components/order/order-details/general-delivery-dialog/general-delivery-dialog.component';
import { OrderDescriptionComponent } from './components/order/order-details/order-description/order-description.component';
import { OrderDetailsComponent } from './components/order/order-details/order-details.component';
import { OrderPickupPointComponent } from './components/order/order-details/order-pickup-point/order-pickup-point.component';
import { OrderUnpaidDialogComponent } from './components/order/order-details/order-unpaid-dialog/order-unpaid-dialog';
import { PreparationNotFinishedDialogComponent } from './components/order/order-details/preparation-not-finished-dialog/preparation-not-finished-dialog.component';
import { PromotionDialogComponent } from './components/order/order-details/promotion-dialog/promotion-dialog.component';
import { ResetTcposDialogComponent } from './components/order/order-details/reset-tcpos-dialog/reset-tcpos-dialog.component';
import { SignatureOrderDialogComponent } from './components/order/order-details/signature-order-dialog/signature-order-dialog.component';
import { SkuDialogComponent } from './components/order/order-details/sku-dialog/sku-dialog.component';
import { SpecifyStorageDialogComponent } from './components/order/order-details/specify-storage-dialog/specify-storage-dialog.component';
import { RemoteDisplayModule } from './components/remote-display/remote-display.module';
import { RoDetailsComponent } from './components/ro/ro-details/ro-details.component';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { ToastMessagesService } from './services/toast-messages.service';
import { SharedModule } from './shared/shared.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    OrderDetailsComponent,
    OrderPickupPointComponent,
    SpecifyStorageDialogComponent,
    CancelOrderDialogComponent,
    SkuDialogComponent,
    PromotionDialogComponent,
    ConfirmDeliveryDialogComponent,
    ConfirmForceCollectedDialogComponent,
    ConfirmForceToCollectDialogComponent,
    PreparationNotFinishedDialogComponent,
    RoDetailsComponent,
    OlDetailsComponent,
    FullPageSpinnerComponent,
    CancelOrderlineDialogComponent,
    ResetTcposDialogComponent,
    GeneralDeliveryDialogComponent,
    BoardingPassComponent,
    SignatureComponent,
    OrderDescriptionComponent,
    SignatureOrderDialogComponent,
    CancelValidationOrderDialogComponent,
    OrderUnpaidDialogComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RemoteDisplayModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ZXingScannerModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    MessageService,
    ToastMessagesService,
    DatePipe,
    { provide: 'Window', useValue: window },
    DialogService,
    { provide: REMOVE_STYLES_ON_COMPONENT_DESTROY, useValue: false },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Materiel,
        options: {
          darkModeSelector: false,
          cssLayer: {
            name: 'primeng',
            order: 'app_styles, primeng',
          },
        },
      },
    }),
  ],
})
export class AppModule {}
