import { Pipe, PipeTransform } from '@angular/core';

import { I18nLabel } from '../models/i18n-label';
import { I18nService } from '../services/i18n.service';

@Pipe({
  name: 'i18nLabel',
  standalone: false,
})
export class I18nLabelPipe implements PipeTransform {
  constructor(private i18nService: I18nService) {}

  transform(value: I18nLabel | undefined): string {
    if (!value || typeof value !== 'object') {
      return '';
    }
    return value[this.i18nService.getLangFull()];
  }
}
