import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-specify-storage-dialog',
  templateUrl: './specify-storage-dialog.component.html',
  styleUrls: ['./specify-storage-dialog.component.scss'],
  standalone: false,
})
export class SpecifyStorageDialogComponent implements OnInit {
  formGroup: UntypedFormGroup = this.fb.group({
    storage: [''],
  });

  constructor(
    public ref: DynamicDialogRef,
    private fb: UntypedFormBuilder,
    private config: DynamicDialogConfig,
  ) {}

  ngOnInit(): void {
    this.formGroup.get('storage')?.setValue(this.config.data?.storage ?? '');
  }

  onSubmit(): void {
    this.ref.close(this.formGroup.get('storage')?.value);
  }

  discard(): void {
    this.ref.close('');
  }

  close(): void {
    this.ref.close(null);
  }
}
