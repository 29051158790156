import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { shareReplay, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { CompanyName } from '../constants';
import { Roles } from '../constants/roles';
import { Credentials } from '../models/credentials';
import { DecodedToken, Shop } from '../models/decoded-token';
import { Token } from '../models/token';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public _token: string;
  private refreshToken: string;
  public decodedToken: DecodedToken;
  public shops: Shop[] = [];
  public shopsIds: string[] = [];
  public shopsCodes: string[] = [];
  public pocIds: string[] = [];
  public isLoggedIn = new Subject(); // when user logs in
  public isLoggedInReplay = new ReplaySubject(1); // when user IS logged in (includes pages refresh)
  public isLoggedOut = new Subject();
  private _refreshObs: Observable<Token> | undefined;
  private window: Window;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    @Inject('Window') window: Window,
  ) {
    this.token = this.storageService.get('token');
    this.refreshToken = this.storageService.get('refresh_token');
    this.window = window;
  }

  set token(token: string) {
    this._token = token;
    if (token) {
      this.decodedToken = jwtDecode<DecodedToken>(token);
      this.decodedToken.shops.forEach((shop) => {
        this.shops.push(shop[Object.keys(shop)[0]]);
        this.shopsIds.push(Object.keys(shop)[0]);
        this.shopsCodes.push(shop[Object.keys(shop)[0]].shopCode);
        this.pocIds = shop[Object.keys(shop)[0]].pocIds;
      });
    } else {
      this.decodedToken = {} as DecodedToken;
    }
  }

  get token() {
    return this._token;
  }

  init(): Observable<any> {
    this.isLoggedInReplay.next(true);
    return of({
      token: this.token,
      refresh_token: this.refreshToken,
    });
  }

  login(credentials: Credentials): Observable<Token> {
    return this.http.post<Token>(`${environment.apiURL}/login`, credentials).pipe(
      tap(this.onToken.bind(this)),
      tap(() => this.isLoggedIn.next(true)),
    );
  }

  azureLogin(selectAccount = false): void {
    this.http
      .get<{ redirect_url: string }>(`${environment.apiURL}/login/redirect-url`)
      .subscribe((d) => {
        let url = d.redirect_url;
        if (selectAccount) {
          // force account selection
          url = `${url}&prompt=select_account`;
        }
        // redirect_url is forced by the backend, but we can override this for dev purposes only
        if (process.env.NG_APP_FORCE_REDIRECT) {
          url = url.replace(/(redirect_uri=)[^&]+/, `$1${this.window.location.origin}/login`);
        }
        this.window.location.href = url;
      });
  }

  refresh(): Observable<Token> {
    if (!this._refreshObs) {
      this._refreshObs = this.http
        .post<Token>(`${environment.apiURL}/token/refresh`, {
          refresh_token: this.refreshToken,
        })
        .pipe(shareReplay(), takeUntil(timer(10000)));
      timer(10000).subscribe(() => (this._refreshObs = undefined));
    }
    return this._refreshObs.pipe(tap(this.onToken.bind(this)));
  }

  onToken(data: Token) {
    this.token = data.token;
    this.refreshToken = data.refresh_token;
    this.storageService.set('token', this.token);
    this.storageService.set('refresh_token', this.refreshToken);
    this.storageService.setUserPrefix(this.decodedToken?.id);
    this.isLoggedInReplay.next(true);
  }

  logout(selectAccount = false) {
    this.isLoggedOut.next(true);
    if (this.token) {
      this.http
        .post(`${environment.apiURL}/logout`, {
          refresh_token: this.refreshToken,
        })
        .subscribe();
    }
    this.storageService.delete('token');
    this.storageService.delete('refresh_token');
    this.token = '';
    this.refreshToken = '';
    this.azureLogin(selectAccount);
  }

  isAdmin(): boolean {
    return (
      this.decodedToken.roles?.includes(Roles.Admin) ||
      this.decodedToken.roles?.includes(Roles.SuperAdmin)
    );
  }

  isSuperAdmin(): boolean {
    return this.decodedToken.roles?.includes(Roles.SuperAdmin);
  }

  isReplenishable(shopId?: string): boolean {
    if (shopId) {
      return !!this.decodedToken.shops.find((shop) => shop[shopId]?.replenishable);
    } else {
      return this.shops.some((shop) => shop.replenishable);
    }
  }

  isADR(shopId: string): boolean {
    return (
      (this.decodedToken.shops.find((shop) => shop[shopId]) ?? {})[shopId].companyName ===
      CompanyName.ADR
    );
  }

  allShopsADR(): boolean {
    return this.shops.every((shop) => shop.companyName === CompanyName.ADR);
  }

  noShopsADR(): boolean {
    return this.shops.every((shop) => shop.companyName !== CompanyName.ADR);
  }
}
